import React from 'react';
import {
  Typography, Grid, Paper, useTheme,
} from '@mui/material';
import accountStyles from '../account/styles';
import bancameLogo from '../../assets/bancame_logo_dark.svg';
import analytics from '../../utils/analytics';

const termsAndConditionsPoints = [
  {
    id: 1,
    value: 'El Programa de Referidos de Banca.me está disponible para cualquier persona que haya recibido un crédito de manera exitosa.',
  },
  {
    id: 2,
    value: `Para participar en el Programa de Referidos, los usuarios deben compartir una URL única generada automáticamente vía correo electrónico en el momento
  en que reciben el dinero del préstamo. La URL puede ser compartida a través de redes sociales, correo electrónico o cualquier otro medio digital para que
  el usuario referido pueda acceder a realizar una solicitud de crédito.`,
  },
  {
    id: 3,
    value: `Por cada referido exitoso (crédito aprobado y transferido), el usuario recibirá un premio que consiste en una transferencia de $10.000 pesos, con
  un límite de hasta 1.000 referidos exitosos.`,
  },
  {
    id: 4,
    value: `La empresa se reserva el derecho de modificar, cancelar o suspender el
    Programa de Referidos de Banca.me en cualquier momento sin previo aviso.`,
  },
  {
    id: 5,
    value: `No se permitirá el uso de técnicas fraudulentas o cualquier otro tipo
    de actividad ilegal para referir usuarios al Programa.`,
  },
  {
    id: 6,
    value: `La empresa se reserva el derecho de descalificar a cualquier usuario que
    se sospeche que está utilizando actividades fraudulentas o ilegales para
    referir usuarios.`,
  },
  {
    id: 7,
    value: `El premio en dinero será transferido a los usuarios a la misma cuenta que
    se utilizó para recibir el préstamo.`,
  },
  {
    id: 8,
    value: `Las transferencias por referidos exitosos serán realizadas durante los
    5 días hábiles siguientes a la recepción de los fondos por parte del referido.`,
  },
  {
    id: 9,
    value: `En caso de que el usuario mantenga obligaciones morosas con Banca.me, el 
    premio será imputado al pago de dichas obligaciones.`,
  },
  {
    id: 10,
    value: `Los usuarios aceptan que la empresa puede utilizar sus nombres y cualquier
    información relacionada con el Programa de Referidos de Banca.me con fines
    publicitarios y de marketing.`,
  },
  {
    id: 11,
    value: `Los usuarios aceptan que la empresa no será responsable por cualquier
    pérdida o daño que resulte del uso del Programa de Referidos de Banca.me.`,
  },
  {
    id: 12,
    value: `Estos términos y condiciones están sujetos a cambios en cualquier
    momento sin previo aviso.`,
  },
  {
    id: 13,
    value: `Al participar en el Programa de Referidos, los usuarios aceptan estos términos y
    condiciones.`,
  },
];

function ReferralTermsAndConditions() {
  const classes = accountStyles();
  const theme = useTheme();

  React.useEffect(() => {
    analytics.page('PU - REFERIDOS', 'TyC PROGRAMA REFERIDOS');
  }, []);

  return (
    <div style={{ height: '100vh', backgroundColor: theme.palette.primary.main }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ padding: { xs: 2, md: 25 } }}
        style={{ backgroundColor: 'inherit' }}
      >
        <Paper
          className={classes.paper}
          elevation={1}
          style={{
            borderRadius: '10px', margin: 0, padding: 20,
          }}
        >
          <div key="referralT&CBody">
            <img src={bancameLogo} style={{ height: '55px', marginBottom: 65 }} alt="BancameLogo" />
            <Typography fontWeight="bold" align="left" variant="h5" component="h4">
              Términos y Condiciones del Programa de Referidos
              banca.me
            </Typography>
            <br />
            <ol>
              {termsAndConditionsPoints.map((tycp) => (
                <div key={tycp.id}>
                  <Typography
                    key={tycp.id}
                    align="justify"
                    variant="body1"
                    component="li"
                    gutterBottom
                    sx={{ lg: { maxWidth: '80%' } }}
                  >
                    {tycp.value}
                  </Typography>
                </div>
              ))}
            </ol>
          </div>
        </Paper>
      </Grid>
    </div>
  );
}

export default ReferralTermsAndConditions;
